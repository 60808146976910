import React from "react"
import Carousel from "components/carousel/carousel"
import Media from "components/media"
import { ButtonYoutubeModal } from "components/modals/youtube-modal"
import { useMobile } from "utils/useMobile"

const Playlist = ({ videos, className }) => {
    const carouselPlaylistRef = React.useRef(null)
    const [currentItem, setCurrentItem] = React.useState(0)
    const isMobile = useMobile()

    const playlistRef = React.useRef(null)
    const [mediaSize, setMediaSize] = React.useState(320)
    const itemsToShowCount = isMobile ? 1 : 3
    const gap = 20
    const infiniteCarousel = true

    React.useLayoutEffect(() => {
        // On resize, track new mediaSize.
        const measure = () => {
            return playlistRef.current && window.requestAnimationFrame(() => {
                const width = playlistRef.current.getBoundingClientRect().width
                setMediaSize(width / itemsToShowCount - gap)
            })
        }
        measure()
        window.addEventListener("resize", measure)
        // Cleanup.
        return () => {
            window.removeEventListener("resize", measure)
        }
    }, [])

    return (
        <div ref={playlistRef} className={"c-playlist" + (className || '')}>
            <div className="c-playlist__bkg u-pd-vt-l u-pd-top-l@main u-pd-bottom-xl@main">
                <div className="c-playlist__trames">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div className={"u-overflow-hidden"}>
                    <Carousel
                        ref={carouselPlaylistRef}
                        slidesToShow={itemsToShowCount}
                        currentItemChange={setCurrentItem}
                        infinite={infiniteCarousel}
                        className="c-carousel">
                        {videos.map((video, i) => (
                            <div key={i} className={"c-carousel__item " + (currentItem === i ? 'is-active' : '')} style={{ padding: `0 ${gap / 2}px`}}>
                                <div className={"c-playlist__card " + (currentItem === i ? 'is-active' : '')}>
                                    <ButtonYoutubeModal url={video.video} className="u-block u-relative u-width-full u-mg-bottom-m">
                                        <div className={"c-playlist__overlay icon-PLAY u-secondary"}>
                                        </div>
                                        {video?.image?.fluid && <Media url={video.image.fluid} alt={video.title} className={"c-playlist__media"} style={{ height: `${mediaSize}px` }}/> }
                                    </ButtonYoutubeModal>
                                    <div className={"c-playlist__content"}>
                                        <h4 className="u-fs-xl u-font-heading u-font-adjustment u-uppercase u-white u-mg-0">{video.title}</h4>
                                        <div className="u-white" dangerouslySetInnerHTML={{__html: video.description}}></div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Carousel>

                    { (infiniteCarousel || videos.length > itemsToShowCount) &&
                        <>
                            <button className="c-playlist__previous icon-FLECHE_SLIDER_LEFT u-mg-top-xl"
                                aria-label={"Précédent"}
                                style={{ top: `${mediaSize / 2}px` }}
                                onClick={() => carouselPlaylistRef.current.previous()}>
                            </button>

                            <button className="c-playlist__next icon-FLECHE_SLIDER_RIGHT u-mg-top-xl"
                                aria-label={"Suivant"}
                                style={{ top: `${mediaSize / 2}px` }}
                                onClick={() => carouselPlaylistRef.current.next()}>
                            </button>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default Playlist
