import React from "react"
import { useModal } from "context/modal"
import Modal from "components/modal"
import DefaultLink from "components/defaultLink"
import YoutubeVideo from "components/youtubeVideo"

const ButtonYoutubeModal = ({ url, btnClassName, btnLabel, children, ...props }) => {
    let { showModal } = useModal()
    return (
        <DefaultLink onClick={() => showModal(YoutubeModal, { url: url })} className={btnClassName} {...props}>
            {children}
        </DefaultLink>
    )
}

const YoutubeModal = ({ url, ...props }) => {
    let { hideModal } = useModal()
    return (
        <Modal onClose={hideModal} modalClassName={"c-modal--primary c-modal--xxl u-pd-l u-pd-xl@main"} {...props}>
            <YoutubeVideo url={url} className={"c-video--sized u-mg-top-l"} style={{ height: "400px" }} />
        </Modal>
    )
}

export { ButtonYoutubeModal, YoutubeModal }

