import React from "react"
import DefaultLink from "components/defaultLink"
import Media from "components/media"

const ActionCard = ({ title, ctaArray, mediaUrl, className }) => {
    return (
        <article className={"c-action-card " + (className || '')}>
            <Media url={mediaUrl} alt={title} className={"c-action-card__bkg"} />
            <div className="c-action-card__overlay"></div>
            <h4 className="c-action-card__title c-h4">{title}</h4>
            <div className="c-action-card__cta">
                {ctaArray && ctaArray.map((item, index) => {
                    return (
                        <DefaultLink key={index} link={item.link} label={item.label} className={item.className + ' u-mg-hz-s'} />
                    )
                })}
            </div>
    </article>
    )
}

ActionCard.defaultProps = {
    mediaUrl: 'https://placeimg.com/640/480/any'
};

export default ActionCard
