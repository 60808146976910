import React from 'react'
import Page from "components/page"
import Layout from "components/layout"
import SEO from "components/seo"
import { graphql, Link } from 'gatsby'
import Media from "components/media"
import DefaultLink from "components/defaultLink"
import { ShareButton } from "components/shareButton"
import ActionCard from "components/blocks/action-card"
import Playlist from "components/blocks/playlist"
import SocialNav from "components/navigation/social-nav"

export const ProfilePage = ({ pageContext, data, ...props }) => {
    const { share } = data.siteData.childContentYaml.modals
    let { slug, headband, act, playlist, blocks, seo } = pageContext
    const otherProfiles = data.otherProfiles.nodes.filter(n => n.slug !== slug).map(({ childMarkdownRemark, ...n }) => ({
        ...n, ...childMarkdownRemark.frontmatter
    }))
    // Add images from data to profile fields.
    headband.image = data.profileWithImages.childMarkdownRemark.frontmatter.headband.image.childImageSharp
    act.actions = act.actions.map(i => !i?.image?.id ? i : ({
        ...i,
        image: data.profileWithImages.childMarkdownRemark.frontmatter.act.actions.find(di => di.image.id === i.image.id)?.image?.childImageSharp
    }))
    playlist.videos = playlist.videos.map(i => !i?.image?.id ? i : ({
        ...i,
        image: data.profileWithImages.childMarkdownRemark.frontmatter.playlist.videos.find(di => di.image.id === i.image.id)?.image?.childImageSharp
    }))
    const { profile: content }  = data.allContentJson.edges[0].node

    return (
        <Page>
            <SEO page={{ ...seo, image: headband.image?.fluid?.src }} />

            <Layout {...props} className={"c-content u-overflow-hidden"}>
                {/* Headband */}
                <div className="c-headband u-pd-top-navbar">
                    <div className="l-container">
                        <div className="c-headband__content u-center u-left@main u-pd-top-l@main">
                            <div className="l-grid">
                                <div className="l-col-12 l-col-6@main l u-pd-vt-m u-pd-vt-xl@main">
                                    <h4 className="c-h4 u-white u-mg-bottom-s">{headband.suptitle || content.headband.suptitle}</h4>
                                    <h1 className="c-h1 c-title-span u-mg-bottom-l">
                                        <span className="title-orange">{headband.title_prefix || content.headband.titlePrefix}</span><br/>
                                        <span className="title-white">{headband.title}</span>
                                    </h1>
                                    <div
                                        className="u-fs-s u-white u-pd-hz-l u-pd-left-0@main u-pd-right-xxl@main u-mg-bottom-m"
                                        dangerouslySetInnerHTML={{ __html: headband.subtitle }}
                                    ></div>
                                    <div className="u-flex u-flex-center-vt u-flex-dir-col u-flex-dir-row@main">
                                        <Link to={`/vous-etes/${slug}`} className={"c-btn c-btn--primary u-pd-hz-l u-mg-right-m u-mg-bottom-m"}>
                                            <span className="icon-FLECHE_CTA u-secondary u-mg-right-s"></span>
                                            <span className="u-font-adjustment">{content.headband.ctaVideo}</span>
                                        </Link>
                                        <ShareButton
                                            className="c-btn c-btn--primary u-pd-hz-l u-mg-right-m u-mg-bottom-m"
                                            shareUrl={`${data.site.siteMetadata.siteUrl}/vous-etes/${slug}`}
                                            shareTitle={content.headband.ctaShare}
                                            shareOptions={share.video}
                                        >
                                            <span className="icon-PARTAGE u-secondary u-mg-right-s"></span>
                                            <span className="u-font-adjustment">{content.headband.ctaShare}</span>
                                        </ShareButton>
                                    </div>
                                </div>

                                <div className="l-col-12 l-col-6@main u-relative u-hide u-block@main u-zi--1">
                                    <div className="u-overflow-hidden u-br-sc u-absolute@main u-top-0 u-width-full u-height-full">
                                        <Media url={headband.image.fluid} width="100%" height="100%" objectFit="contain" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="l-container">
                    <div className="c-trame-profil"></div>
                </div>

                {/* Act */}
                <section className="u-pd-vt-xl u-pd-vt-xxl@main">
                    <div className="l-container">
                        <div className="u-pd-hz-l u-mg-left-4col@main">
                            <h2 className="c-h2 c-profile-title u-center u-left@main u-bold u-secondary u-mg-bottom-m" dangerouslySetInnerHTML={{ __html: act.title }}></h2>
                        </div>
                        <div className="l-grid-card u-pd-hz-l u-pd-hz-0@main u-mg-bottom-l">
                            {act.actions.map((action, i) => {
                                let offsetSizes = [0, 'xxl', 'l']
                                return (
                                    <div key={i} className={`u-pd-top-${offsetSizes[i]}@main u-mg-top-${offsetSizes[i]}@main`}>
                                        <ActionCard
                                            title={action.title}
                                            ctaArray={
                                                [{
                                                    link: action.button.url,
                                                    label: action.button.text,
                                                    className: "c-btn c-btn--primary"
                                                },{
                                                    link: action.button_donate.url,
                                                    label: action.button_donate.text,
                                                    className: "c-btn c-btn--secondary"
                                                }]
                                            }
                                            mediaUrl={action.image?.fluid}
                                            className={'u-mg-bottom-xxl'}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                        <div className="u-flex u-flex-center-hz u-pd-hz-m">
                            <DefaultLink link={act.button.url} className={"c-btn c-btn--primary u-pd-hz-l u-mg-right-m@main u-mg-bottom-m"}>
                                <span className="icon-FLECHE_CTA u-secondary u-mg-right-s"></span>
                                <span className="u-font-adjustment">{act.button.text}</span>
                            </DefaultLink>
                        </div>
                    </div>
                </section>

                {/* Playlist */}
                <section className="u-pd-vt-xxl">
                    <div className="l-container">
                        <div className="l-grid">
                            <div className="l-col-12 l-col-6">
                                <h2 className="c-h2 c-title-span c-playlist-title u-center u-left@main u-bold u-secondary u-mg-bottom-m u-relative u-zi-1" dangerouslySetInnerHTML={{ __html: playlist.title }}></h2>
                            </div>
                        </div>
                        <Playlist videos={playlist.videos} />
                    </div>
                </section>

                {/* Join */}
                <section className="u-pd-vt-xxl u-pd-hz-m u-bg-secondary u-overflow-hidden">
                    <div className="l-container u-relative">
                        <span className="icon-RS_linkedin-Copy u-orange-light u-absolute u-right-0 u-zi--1" style={{ fontSize: '35rem', top: '50%', transform: 'translate(33%, -43%)' }}></span>
                        <div className="l-grid">
                            <div className="l-col-12 l-col-7@main">
                                <h3 className="c-h3 c-title-span u-primary u-mg-bottom-m u-mg-0@main" dangerouslySetInnerHTML={{ __html: blocks.join.title }}></h3>
                            </div>
                            <div className="l-col-12 l-col-5@main u-flex u-flex-dir-col u-flex-end">
                                <DefaultLink
                                    link={blocks.join.button.url}
                                    label={blocks.join.button.text}
                                    className={"c-btn c-btn--primary u-width-full u-pd-vt-m"}
                                />
                            </div>
                        </div>
                    </div>
                </section>

                {/* Donate */}
                <section className="u-pd-vt-xxl u-pd-hz-l u-pd-hz-0@main">
                    <div className="l-container">
                        <div className="l-grid">
                            <div className="l-col-12 l-col-5@main u-pd-right-xl@main u-center u-left@main">
                                <h3 className="c-h3 u-secondary u-mg-bottom-l" dangerouslySetInnerHTML={{ __html: blocks.donate.title }}></h3>
                                <div className="u-primary u-fs-s u-mg-bottom-l" dangerouslySetInnerHTML={{ __html: blocks.donate.description }}></div>
                            </div>
                            <div className="l-col-12 l-col-7@main u-flex u-flex-dir-col u-flex-center-hz u-flex-center-vt u-pd-hz-l">
                                <DefaultLink
                                    link={blocks.donate.button.url}
                                    label={blocks.donate.button.text}
                                    className={"c-btn c-btn--secondary u-width-full u-pd-vt-m u-mg-bottom-l"}
                                />
                                {blocks.donate.social.show && (
                                    <>
                                        <p className="u-font-heading u-fs-l u-bold u-uppercase u-center u-primary u-mg-bottom-s">{blocks.donate.social.text}</p>
                                        <SocialNav className="u-fs-xl u-primary u-flex u-flex-center-hz" />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </section>

                <hr className="l-container" />

                {/* Discover */}
                <section className="u-pd-vt-xxl">
                    <div className="l-container">
                        <div className="l-grid u-pd-hz-l u-pd-hz-xl@main">
                            <div className="l-col-12 l-col-5@main u-pd-right-xl@main u-center u-left@main">
                                <h3 className="c-h3 u-primary u-mg-bottom-xl" dangerouslySetInnerHTML={{ __html: blocks.discover.title }}></h3>
                                <div className="u-desktop-only u-mg-bottom-l">
                                    <ShareButton btnClassName={"c-btn c-btn--secondary u-pd-hz-l u-mg-hz-auto u-mg-hz-0"}>
                                        <span className="icon-FLECHE_CTA u-primary u-mg-right-s"></span>
                                        <span className="u-font-adjustment">{blocks.discover.button_text}</span>
                                    </ShareButton>
                                </div>
                            </div>
                            <div className="l-col-12 l-col-7@main u-flex u-flex-dir-col u-flex-center-hz u-flex-center-vt u-pd-hz-m u-pd-hz-xl@main">
                                {otherProfiles.map((otherProfile, i) => (
                                    <DefaultLink
                                        key={i}
                                        link={`/profils/${otherProfile.slug}`}
                                        label={otherProfile.title}
                                        className={"c-btn c-btn--outline-secondary u-width-full u-pd-vt-m u-mg-bottom-m"}
                                    />
                                ))}
                                <div className="u-mobile-only u-mg-top-l">
                                    <ShareButton btnClassName={"c-btn c-btn--secondary u-pd-hz-l u-mg-hz-auto u-mg-hz-0"}>
                                        <span className="icon-FLECHE_CTA u-primary u-mg-right-s"></span>
                                        <span className="u-font-adjustment">{blocks.discover.button_text}</span>
                                    </ShareButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </Page>
    )
}

export const query = graphql`
    query QueryProfile($id: String!) {
        site {
            siteMetadata {
                siteUrl
            }
        }
        allContentJson {
            edges {
                node {
                    profile {
                        headband {
                            suptitle
                            titlePrefix
                            ctaVideo
                            ctaShare
                        }
                    }
                }
            }
        }
        siteData: file(name: {eq: "site"}, sourceInstanceName: {eq: "site"}) {
            childContentYaml {
                modals {
                    share {
                        video {
                            default_message
                            facebook_message
                            twitter_message
                            linkedin_message
                            email_subject
                            email_message
                        }
                    }
                }
            }
        }
        otherProfiles: allFile(filter: {sourceInstanceName: {eq: "profiles"}, name: { eq: "index"}, id: { ne: $id }}) {
            nodes {
                id
                name
                childMarkdownRemark {
                    id
                    frontmatter {
                        slug
                        title
                    }
                }
            }
        }
        profileWithImages: file(id: {eq: $id}) {
            childMarkdownRemark {
                frontmatter {
                    headband {
                        image {
                            id
                            childImageSharp {
                                fluid(maxWidth: 1200, quality: 100, webpQuality: 100) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                    act {
                        actions {
                            image {
                                id
                                childImageSharp {
                                    fluid(maxWidth: 400) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                    }
                    playlist {
                        videos {
                            image {
                                id
                                childImageSharp {
                                    fluid(maxWidth: 400) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

export default ProfilePage